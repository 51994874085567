import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { useConversionTracker } from 'ha/modules/Analytics/hooks';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';
import { useServices } from 'ha/services';

import { usePageComponentsInfo } from 'ha/modules/Page/contexts';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';
import {
  getListings,
  getCityCanonical,
  getCityName,
  getCountryCode,
} from 'ha/pages/Search/selectors';
import { ToggleMapFixedButton } from 'ha/pages/SearchRedesign/components/SearchControlsMapToggle';
import { SearchMapButtonToggle } from 'ha/pages/SearchRedesign/components/SearchControlsMapToggle/SearchControlsMapButtonToggle';
import { SearchControlsSort } from 'ha/pages/SearchRedesign/components/SearchControlsSort/SearchControlsSort';
import {
  SearchResultsEmpty,
  SearchResultsFewerThanExpected,
} from 'ha/pages/SearchRedesign/components/SearchResultsGuide';
import { SearchResultsInfo } from 'ha/pages/SearchRedesign/components/SearchResultsInfo/SearchResultsInfo';
import { LoadableSearchResultsMapNotification } from 'ha/pages/SearchRedesign/components/SearchResultsMapNotification';

import { SearchResultsNotificationToAddDatesOnFilter as SearchDatesFilterNotification } from '../../components/SearchDatesFilterNotification';
import { SearchResultsBottom } from '../../components/SearchResultsBottom';
import { getMapViewState } from '../../selectors/mapSelectors';
import { MapViewState } from '../../types';

import { SearchResultsListSkeleton } from './SearchResultsListSkeleton';
import { SearchResultsListWithBannerCard } from './SearchResultsListWithBannerCard';

const useSearchResultsTopSectionStyles = makeStyles()(theme => ({
  searchResultsTopSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.utils.spacing('ref/spacing/2'),
    marginBottom: theme.utils.spacing('ref/spacing/2'),
    gap: theme.utils.spacing('ref/spacing/4'),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.utils.spacing('ref/spacing/4'),
      marginBottom: theme.utils.spacing('ref/spacing/4'),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  infoSection: {
    flex: 1,
  },
  buttonsSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.utils.spacing('ref/spacing/2'),
    flex: '0 0 auto',
  },
}));

const SearchResultsTopSection = () => {
  const { classes, theme } = useSearchResultsTopSectionStyles();
  const listingsCount = useSelector(getListings).length;
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const mapViewState = useSelector(getMapViewState);

  const isMapOpen = [MapViewState.halfMap, MapViewState.fullMap].includes(
    mapViewState,
  );

  return (
    <div className={classes.searchResultsTopSectionContainer}>
      <LoadableSearchResultsMapNotification />
      <div className={classes.container}>
        <div className={classes.infoSection}>
          <SearchResultsInfo />
        </div>

        <div className={classes.buttonsSection}>
          {listingsCount > 0 && <SearchControlsSort />}
          {isLargerThanMd && !isMapOpen && (
            <SearchMapButtonToggle origin="SearchResultsTopSection" />
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: theme.spacing(6),

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('md')]: {
      gap: '40px',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  },
  containerWithSplitView: {
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(5),
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('md')]: {},

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
  },
}));

const SearchResultsLayout = ({ children }: React.PropsWithChildren) => {
  const { classes, cx } = useStyles();
  const mapViewState = useSelector(getMapViewState);

  const showMap = [MapViewState.halfMap, MapViewState.fullMap].includes(
    mapViewState,
  );

  return (
    <React.Fragment>
      <SearchResultsTopSection />
      <div
        className={cx(classes.container, {
          [classes.containerWithSplitView]: showMap,
        })}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

const SearchResults: React.FC = () => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const isFullMapView = useSelector(getMapViewState) === MapViewState.fullMap;

  const listings = useSelector(getListings);
  const cityCanonical = useSelector(getCityCanonical);
  const countryCode = useSelector(getCountryCode);
  const cityLocalizedName = useSelector(getCityName);
  const { bottomNavbarHeight } = usePageComponentsInfo();

  const { analytics } = useServices();
  const { trackSearchView } = useConversionTracker();
  const { rebrandNewDateOnboarding } = useFeatureFlags();
  React.useEffect(() => {
    analytics.onUserIdentified(() =>
      trackSearchView(listings, countryCode || ''),
    );
  }, [trackSearchView, listings, countryCode, analytics]);

  return (
    <React.Fragment>
      {listings.length > 0 ? (
        <React.Fragment>
          {!isEnabled(rebrandNewDateOnboarding) && (
            <SearchDatesFilterNotification />
          )}
          <RebrandThemeProvider>
            <SearchResultsLayout>
              <SearchResultsListSkeleton />
              <SearchResultsListWithBannerCard items={listings} />
            </SearchResultsLayout>
          </RebrandThemeProvider>
        </React.Fragment>
      ) : (
        <RebrandThemeProvider>
          <SearchResultsTopSection />
          <SearchResultsEmpty />
        </RebrandThemeProvider>
      )}

      <SearchResultsFewerThanExpected resultsCount={listings.length} />

      <SearchResultsBottom
        cityCanonical={cityCanonical}
        countryCode={countryCode}
        cityLocalizedName={cityLocalizedName}
      />

      {!isFullMapView && isSmallerThanMd && (
        <ToggleMapFixedButton
          bottomPosition={bottomNavbarHeight}
          data-test-locator="SearchResults/ToggleMapFixedButton"
        />
      )}
    </React.Fragment>
  );
};

export { SearchResults };
