import { createSelector } from 'reselect';

import { Experiments } from 'ha/constants/experiments';

import { getFeatureFlags, isEnabled } from 'ha/modules/FeatureFlags';
import { SortingValue } from 'ha/types/SearchFilters';
import { GlobalState } from 'ha/types/store';

import { RankingExperimentWhitelistedCities } from 'ha/modules/Experiments/constants';
import { getSelectedVariants } from 'ha/modules/Experiments/selectors';

import { getCachedUpdateSearch } from './getRequestCache';

export const getCurrentSorting = (state: GlobalState) =>
  state.search.sorting.currentSorting;

export const getSortingOptions = (state: GlobalState) =>
  state.search.sorting.indexes;

export const getRankingExperimentVariant = (state: GlobalState) => {
  const { useBestMatchExperimentIndex } = getFeatureFlags(state);
  const cityCanonical = getCachedUpdateSearch(state)?.city || '';
  const experiments = getSelectedVariants(state);

  const rankingExperimentVariant = experiments[Experiments.TX1828.name];
  const isRankingExperimentActive =
    isEnabled(useBestMatchExperimentIndex) &&
    RankingExperimentWhitelistedCities.includes(
      cityCanonical.toLocaleLowerCase(),
    );

  if (!isRankingExperimentActive) {
    return undefined;
  }

  return rankingExperimentVariant;
};

// https://housinganywhere.atlassian.net/browse/TX-1986
const getIsRankingExperimentEnabled = createSelector(
  [getCurrentSorting, getRankingExperimentVariant],
  (currentSorting, rankingExperimentVariant) =>
    rankingExperimentVariant === 'B' &&
    // sorting must be set to recommended
    currentSorting === SortingValue.BestMatch,
);

export const getCurrentIndex = createSelector(
  [getCurrentSorting, getSortingOptions, getIsRankingExperimentEnabled],
  (currentSorting, sortingOptions, isRankingExperimentEnabled) => {
    if (isRankingExperimentEnabled) {
      return sortingOptions?.indexExperiment;
    }

    return sortingOptions?.[currentSorting];
  },
);
