import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider';
import { ToggleMapFixedButton } from 'ha/pages/SearchRedesign/components/SearchControlsMapToggle';
import { ModalNext as Modal } from 'ha/ui/Modal';

import { MobileMapHeader } from '../redesign/components/MobileMapHeader';

import { LoadableSearchMap as SearchMap } from './SearchMap.lazy';

const useStyles = makeStyles()(() => ({
  mobileMapBackdrop: {
    // use the same color as the map while it's loading
    backgroundColor: 'rgb(221, 221, 221) !important',
  },
}));

interface Props {
  isMobileMapOpen: boolean;
  toggleMobileMap: () => void;
}

export const MobileMap: React.FC<Props> = ({
  isMobileMapOpen,
  toggleMobileMap,
}) => {
  const { classes } = useStyles();

  return (
    <Modal
      open={isMobileMapOpen}
      disablePortal
      BackdropProps={{ className: classes.mobileMapBackdrop }}
      onClose={toggleMobileMap}
    >
      <React.Fragment>
        <SearchMap
          slots={{
            headerSection: (
              <RebrandThemeProvider>
                <MobileMapHeader />
              </RebrandThemeProvider>
            ),
          }}
        />
        <ToggleMapFixedButton data-test-locator="MobileMap/ToggleMapFixedButton" />
      </React.Fragment>
    </Modal>
  );
};
