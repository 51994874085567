import { Thunk } from 'ha/myredux/types';
import { User } from '@ha/api/v2/types/User';
import { UpdateUserPayload } from '@ha/api/v2/updateUser';
import { AuthLogicActions } from './actions';

export const updateUser: (id: number, newValues: UpdateUserPayload) => Thunk =
  (userId, newValues) =>
  (dispatch, _, { apiV2 }) =>
    apiV2.updateUser(userId, newValues).then(newUser => {
      const userData = newUser.data as Partial<User>;

      delete userData.avatarURL;

      return dispatch(AuthLogicActions.updateUser(userData));
    });
