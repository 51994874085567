import React from 'react';

import { useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { SEARCH_PAGE_MAX_WIDTH } from 'ha/constants/search';

import { useIntl } from 'ha/i18n';
import { useFeatureFlags } from 'ha/modules/FeatureFlags';

import { updateMapViewAndParams } from '../../actions';
import { LoadableSearchMap as SearchMap } from '../../components/SearchMap.lazy';
import { SearchCityAbout, SearchCityLinks } from '../../components/SearchSEO';
import {
  NEW_ABOUT_SEO_CONFIG,
  SEARCH_LAYOUT_LISTINGS_CONTAINER,
} from '../../constants';
import { MobileMapContainer as MobileMap } from '../../containers/MobileMap';
import { getCountryCode } from '../../selectors';
import { getMapViewState } from '../../selectors/mapSelectors';
import { MapViewState } from '../../types';

import { SearchResults } from './SearchResults';

const HALF_MAP_COL_WIDTH = '40%';

const useStyles = makeStyles()(theme => ({
  container: {
    position: 'relative',
    width: '100vw',
    height: '100%',
    maxWidth: SEARCH_PAGE_MAX_WIDTH,
    margin: 'auto',
    padding: theme.utils.spacing('ref/spacing/0'),

    '--x-inline-padding': `${theme.utils.spacing('ref/spacing/5')}px`,
    paddingInline: theme.utils.spacing('ref/spacing/5'),

    [theme.breakpoints.up('md')]: {
      '--x-inline-padding': `${theme.utils.spacing('ref/spacing/10')}px`,
      paddingInline: theme.utils.spacing('ref/spacing/10'),
    },

    [theme.breakpoints.up('lg')]: {
      '--x-inline-padding': `${theme.utils.spacing('ref/spacing/20')}px`,
      paddingInline: theme.utils.spacing('ref/spacing/20'),
    },
  },

  listingsContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  listingsContainerContract: {
    width: `calc(100% - ${HALF_MAP_COL_WIDTH})`,
    overflow: 'auto',
    paddingTop: theme.utils.spacing('ref/spacing/0'),
    paddingRight: theme.utils.spacing('ref/spacing/5'),
    display: 'block',
  },

  listingsContainerExpand: {
    width: '100%',
    display: 'block',
  },

  listingsContainerHide: {
    display: 'none',
  },

  mapContainer: {
    position: 'absolute',
    right: 0,
    width: 0,
    top: 0,
    overflow: 'hidden',
    height: '100%',
  },

  mapContainerHide: {
    display: 'none',
  },

  mapContainerHalfExpand: {
    width: HALF_MAP_COL_WIDTH,
    display: 'block',
  },

  mapContainerFullExpand: {
    width: '100%',
    display: 'block',
  },
}));

const SeoContent: React.FC = React.memo(() => {
  const location = useLocation();
  const { urlResolver } = useIntl();
  const { showNewAboutSEO } = useFeatureFlags();

  const countryCode = useSelector(getCountryCode);
  const isSearchCityPage = urlResolver.isSearchCityPage(location.pathname);

  const showSEOContent =
    showNewAboutSEO === 'on' &&
    isSearchCityPage &&
    NEW_ABOUT_SEO_CONFIG.countryCodes.includes(countryCode.toLowerCase());

  if (!showSEOContent) return null;

  return (
    <React.Fragment>
      <SearchCityAbout />
      <SearchCityLinks />
    </React.Fragment>
  );
});

const SearchLayoutInternal = () => {
  const { classes, cx, theme } = useStyles();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const isMapInitialized = React.useRef(false);
  const mapViewState = useSelector(getMapViewState);

  const showMap = [MapViewState.halfMap, MapViewState.fullMap].includes(
    mapViewState,
  );

  React.useEffect(() => {
    if (isMapInitialized.current) return;

    isMapInitialized.current = showMap;
  }, [showMap]);

  return (
    <div className={classes.container}>
      <div
        id={SEARCH_LAYOUT_LISTINGS_CONTAINER}
        className={cx(classes.container, classes.listingsContainer, {
          [classes.listingsContainerExpand]:
            mapViewState === MapViewState.noMap,
          [classes.listingsContainerContract]: showMap,
          [classes.listingsContainerHide]:
            mapViewState === MapViewState.fullMap,
        })}
      >
        <div data-test-locator="Search Listings Grid">
          {mapViewState !== MapViewState.fullMap && <SearchResults />}
        </div>
        <SeoContent />
      </div>
      <div
        data-test-locator="Search Listings Map"
        className={cx(classes.mapContainer, {
          [classes.mapContainerHide]: !showMap,
          [classes.mapContainerHalfExpand]: showMap,
          [classes.mapContainerFullExpand]:
            mapViewState === MapViewState.fullMap,
        })}
      >
        {isMapInitialized.current &&
          (isSmallerThanMd ? <MobileMap /> : <SearchMap />)}
      </div>
    </div>
  );
};

export const SearchLayout = () => {
  const { theme } = useStyles();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const mapViewState = useSelector(getMapViewState);

  // if user is in halfMap state & resizes window from desktop to mobile,
  // we will reset state to `noMap` view
  React.useEffect(() => {
    if (isSmallerThanMd && mapViewState === MapViewState.halfMap) {
      dispatch(updateMapViewAndParams({ mapView: MapViewState.noMap }));
    }
  }, [isSmallerThanMd, mapViewState, dispatch]);

  return <SearchLayoutInternal />;
};
