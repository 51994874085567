import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { SearchMapChipToggle } from 'ha/pages/SearchRedesign/components/SearchControlsMapToggle/SearchControlsMapChipToggle';

const useStyles = makeStyles<{
  bottomPosition?: number;
}>()((theme, { bottomPosition = 0 }) => ({
  root: {
    position: 'fixed',
    bottom: theme.utils.spacing('ref/spacing/4') + bottomPosition,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

interface Props {
  bottomPosition?: number;
  'data-test-locator'?: string;
}

export const ToggleMapFixedButton = ({
  bottomPosition = 0,
  'data-test-locator': dataTestId,
}: React.PropsWithChildren<Props>) => {
  const { classes } = useStyles({ bottomPosition });

  return (
    <div className={classes.root} data-test-locator={dataTestId}>
      <SearchMapChipToggle />
    </div>
  );
};
