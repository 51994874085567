import React from 'react';

interface SearchPageContextInterface {
  hoveredCardId?: number;
  setHoveredCardId: (hoveredCardId?: number) => void;
}

const SearchPageContext = React.createContext<SearchPageContextInterface>({
  setHoveredCardId: () => {},
});

export const SearchPageProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [hoveredCardId, setHoveredCardId] = React.useState<number>();

  const value = React.useMemo(
    () => ({
      hoveredCardId,
      setHoveredCardId,
    }),
    [hoveredCardId],
  );

  return (
    <SearchPageContext.Provider value={value}>
      {children}
    </SearchPageContext.Provider>
  );
};

export const useSearchPageContext = () => React.useContext(SearchPageContext);
