import React from 'react';

import { useSelector } from 'react-redux';

import { syncDayWithUTC } from '@ha/date';
import { DateTimeFormat } from '@ha/intl';

import { useIntl } from 'ha/i18n';

import { SearchDialog } from 'ha/components/SearchDialog';
import { getFlexDatesString } from 'ha/components/SearchDialog/helpers/getFlexDatesString';
import { SearchInputButton } from 'ha/components/SearchInputButton/SearchInputButton';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { usePage } from 'ha/modules/Page/contexts';
import {
  getCityLocalized,
  getCountryLocalized,
  getDateFilter,
  getAdvertiserId,
  getNumberOfListings,
} from 'ha/pages/Search/selectors';

import { useShouldUseMapBounds } from '../../hooks/useShouldUseMapBounds';
import { DateFilterState } from '../../reducer/types';

export const SearchDates = ({ dates }: { dates: DateFilterState }) => {
  const { T, formatDateTime } = useIntl();
  const dateRangeString = React.useMemo(() => {
    if (!dates.startDate) return T('header.search.add_dates');

    const startDate = syncDayWithUTC(new Date(dates.startDate));

    const formattedStartDate = formatDateTime(
      startDate,
      DateTimeFormat.DATE_SHORT,
    );

    // eslint-disable-next-line custom/no-restricted-translation-concatenation-with-template-literal
    if (!dates.endDate) {
      return `${formattedStartDate} – ${T('header.search.no_end')}`;
    }

    const endDate = syncDayWithUTC(new Date(dates.endDate));

    const isDifferentYear = startDate.getFullYear() !== endDate.getFullYear();
    const formattedEndDate = formatDateTime(endDate, DateTimeFormat.DATE_SHORT);

    if (isDifferentYear) return `${formattedStartDate} – ${formattedEndDate}`;

    const formattedStartDateShort = formatDateTime(startDate, {
      month: 'short',
      day: 'numeric',
    });
    const flexDateString = getFlexDatesString(T, dates.flexDays);
    return `${formattedStartDateShort} – ${formattedEndDate} ${flexDateString}`;
  }, [T, dates, formatDateTime]);

  return <span>{dateRangeString}</span>;
};

export const SearchHeader = () => {
  const { T } = useIntl();
  const cityLocalized = useSelector(getCityLocalized);
  const countryLocalized = useSelector(getCountryLocalized);
  const selectedDates = useSelector(getDateFilter);
  const numberOfListings = useSelector(getNumberOfListings);
  const advertiserId = useSelector(getAdvertiserId);
  const { shouldUseMap } = useShouldUseMapBounds();

  const { category } = usePage();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const initialValues = {
    place: [cityLocalized, countryLocalized].join(', '),
    dateRange: selectedDates,
    userId: advertiserId,
  };

  const renderInputText = () => {
    if (shouldUseMap) {
      return T('search_page.search_bar.map_area');
    }

    return cityLocalized;
  };

  return (
    <React.Fragment>
      <Track
        page={category}
        category={category}
        name="Clicked City-Date selection"
      >
        <SearchInputButton
          onClick={() => setIsDialogOpen(true)}
          isDatesSelected={!!selectedDates.startDate}
          showTooltip
        >
          {renderInputText()}, <SearchDates dates={selectedDates} />
        </SearchInputButton>
      </Track>

      <SearchDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        initialValues={initialValues}
        initialNumberOfListings={numberOfListings}
      />
    </React.Fragment>
  );
};
