import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { UserInformation } from '@ha/api/v2/types/UserInformation';

import {
  BILLING_INFO_STORAGE_KEY,
  FetchUserBillingInfoTypes,
} from 'ha/modules/AuthLogic/constants';
import { Thunk } from 'ha/myredux/types';
import { notEmpty } from 'ha/utils/notEmpty';
import {
  getFromStorage,
  setInStorage,
  removeFromStorage,
} from 'ha/utils/storage';

export const Actions = {
  start: () => createAction(FetchUserBillingInfoTypes.start),
  done: (data: UserInformation) =>
    createAction(FetchUserBillingInfoTypes.done, data),
  fail: (error: Error) => createAction(FetchUserBillingInfoTypes.fail, error),
};
export type ActionsType = ActionsUnion<typeof Actions>;

const fetchAndCacheBillingInfo =
  (): Thunk =>
  (dispatch, getState, { apiV2 }) => {
    dispatch(Actions.start());

    return Promise.all([
      apiV2.getUserInformation('me', {
        expand: 'savedPayoutMethodsCount,advertiserBookingsCount,isKYCDone',
      }),
      apiV2.getMultiBillingInformation({ limit: 1 }),
    ])
      .then(
        ([
          { data: userInformationFromHa },
          {
            data: { count },
          },
        ]) => {
          const userInformation = {
            ...userInformationFromHa,
            hasCompleteBillingInformation: Boolean(count),
          };

          dispatch(Actions.done(userInformation));

          setInStorage(BILLING_INFO_STORAGE_KEY, userInformation);
        },
      )
      .catch(error => {
        dispatch(Actions.fail(error));

        removeFromStorage(BILLING_INFO_STORAGE_KEY);
      });
  };

// Fetch user billing information and cache it.
// - use cache, if user doesn't have bookings check that first to potentially invalidate cache.
// - Cache values are invalidated when adding/removing payout methods,
//   and when saving billing information.
//
// This is done to avoid calling API for data that need to be fetched from other
// microservice ('savedPayoutMethodsCountd' & 'hasCompleteBillingInformation').

export const fetchUserBillingInfo: () => Thunk =
  () =>
  (dispatch, getState, { apiV2 }) => {
    const { authLogic } = getState();

    if (!authLogic.user) {
      return;
    }

    const data: UserInformation | undefined = getFromStorage(
      BILLING_INFO_STORAGE_KEY,
    );

    const noCache = !notEmpty(data);

    if (noCache) {
      return dispatch(fetchAndCacheBillingInfo());
    }

    if (data && data.advertiserBookingsCount > 0) {
      dispatch(Actions.done(data));

      return Promise.resolve();
    }

    return apiV2
      .getUserInformation('me', { expand: 'advertiserBookingsCount' })
      .then(res => {
        if (data && res.data && res.data.advertiserBookingsCount === 0) {
          dispatch(Actions.done(data));

          return;
        }

        // eslint-disable-next-line consistent-return
        return dispatch(fetchAndCacheBillingInfo());
      })
      .catch(() => dispatch(fetchAndCacheBillingInfo()));
  };
