import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { getCityCanonical } from 'ha/pages/Cities/selectors';
import { toggleMobileMap, updateMapView } from 'ha/pages/Search/actions';
import { MapViewState } from 'ha/pages/Search/types';

import { CHANGE_MAP_VIEW_EVENT_NAME } from '../../../modules/Search/constants';

export const useMapViewHandler = () => {
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();
  const track = useTrackEvent();

  const cityCanonical = useSelector(getCityCanonical);

  const changeMapView = React.useCallback(
    (nextView: MapViewState) => {
      if (isLargerThanMd) {
        dispatch(updateMapView(nextView));
      } else {
        dispatch(toggleMobileMap());
      }
      track(CHANGE_MAP_VIEW_EVENT_NAME, {
        mapType: nextView,
        listingcity: cityCanonical,
      });
    },
    [cityCanonical, dispatch, isLargerThanMd, track],
  );

  return {
    changeMapView,
  };
};
