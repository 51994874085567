/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { playExperiment } from 'ha/modules/Experiments';
import { getUserIdentifier } from 'ha/modules/Experiments/selectors';
import { useMediaQuery } from 'ha/helpers/MediaQuery';

import { toggleMobileMap, updateMapView } from '../actions';
import { getMapViewState } from '../selectors';
import {
  getSplitMapExperimentDesktop,
  getSplitMapExperimentMobile,
} from '../selectors/splitMapExperiment';
import { MapViewState } from '../types';

export const useSplitMapExperiment = () => {
  // TODO switch to useMediaQuery(theme.breakpoints.down('md')) only when the SearchRoot
  // is fully redesinged and wrapped with provider
  const { md } = useMediaQuery();
  const isSmallerThanMd = !md;
  const dispatch = useDispatch();
  const userIdentifier = useSelector(getUserIdentifier);

  const mapViewState = useSelector(getMapViewState);

  const {
    isSplitMapDesktopExperimentActive,
    splitMapDesktopExperimentVariant,
    splitMapDesktopExperimentName,
  } = useSelector(getSplitMapExperimentDesktop);

  React.useEffect(() => {
    // For Desktops

    if (isSmallerThanMd) return;

    if (!isSplitMapDesktopExperimentActive) return;

    dispatch(
      playExperiment(
        splitMapDesktopExperimentName,
        splitMapDesktopExperimentVariant,
        userIdentifier,
      ),
    );

    if (
      mapViewState === MapViewState.noMap &&
      splitMapDesktopExperimentVariant === 'B'
    ) {
      dispatch(updateMapView(MapViewState.halfMap));
    }
  }, [
    dispatch,
    isSmallerThanMd,
    isSplitMapDesktopExperimentActive,
    splitMapDesktopExperimentVariant,
    splitMapDesktopExperimentName,
    userIdentifier,
  ]);

  const {
    splitMapMobileExperimentName,
    isSplitMapMobileExperimentActive,
    splitMapMobileExperimentVariant,
  } = useSelector(getSplitMapExperimentMobile);

  React.useEffect(() => {
    // For Mobiles

    if (!isSmallerThanMd) return;

    if (!isSplitMapMobileExperimentActive) return;

    dispatch(
      playExperiment(
        splitMapMobileExperimentName,
        splitMapMobileExperimentVariant,
        userIdentifier,
      ),
    );

    if (
      mapViewState === MapViewState.noMap &&
      splitMapMobileExperimentVariant === 'B'
    ) {
      dispatch(toggleMobileMap());
    }
  }, [
    dispatch,
    userIdentifier,
    isSmallerThanMd,
    splitMapMobileExperimentName,
    isSplitMapMobileExperimentActive,
    splitMapMobileExperimentVariant,
  ]);
};
