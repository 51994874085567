import React from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { NoSSR } from '@hbf/dsl/legacy';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { AuthPageQueryActions, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { updateUser } from 'ha/modules/AuthLogic/actions';
import { getUserId, getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { SearchAlertChannel, SearchAlertFrequency } from 'ha/services/bell';

import { send as showAlert } from 'ha/modules/AlertBar/actions';
import { AlertType } from 'ha/modules/AlertBar/types';
import { SearchAlertDialog } from 'ha/modules/SearchAlerts';
import { RebrandFontProvider } from 'ha/modules/ThemeProvider';
import { PhoneNumberProps } from 'ha/ui/PhoneInput';

import { upsertSearchAlert } from '../../actions';
import { useQueryParams } from '../../hooks/useQueryParams';
import { getCityName } from '../../selectors';
import {
  hasSearchAlert,
  getSearchAlert,
  isSearchAlertDisabled,
} from '../../selectors/searchAlertSelectors';

import { SearchAlertResponseDialog } from './SearchAlertResponseDialog';

export type DialogType =
  | 'main'
  | 'response-create'
  | 'response-update'
  | undefined;

export const LegacySearchAlertDialog = ({
  dialogType,
  setDialogType,
}: {
  dialogType: DialogType;
  setDialogType: (type: DialogType) => void;
}) => {
  const dispatch = useDispatch();
  const { T, urlResolver } = useIntl();
  const trackEvent = useTrackEvent();
  const { removeUpdateSearchAlertParam } = useQueryParams();

  const userId = useSelector(getUserId)!;
  const location = useLocation();
  const hasAlert = useSelector(hasSearchAlert);
  const cityNameLocalized = useSelector(getCityName);
  const currentAlert = useSelector(getSearchAlert) || undefined;

  const { md } = useMediaQuery();
  const isAuthenticated = Boolean(useSelector(getIsAuthenticated));
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (dialogType === 'main' && !isAuthenticated) {
      const params = new URLSearchParams(searchParams);

      params.set('showCreateSearchAlert', '1');

      const returnUrl = `${location.pathname}?${params.toString()}`;

      window.location.assign(
        urlResolver.getOAuthSignupUrl(returnUrl, {
          target: AuthPageTarget.Tenant,
          action: AuthPageQueryActions.CREATE_SEARCH_ALERT,
        }),
      );
    }
  }, [
    dialogType,
    dispatch,
    isAuthenticated,
    location.pathname,
    searchParams,
    setDialogType,
    urlResolver,
  ]);

  const isDisabled = Boolean(useSelector(isSearchAlertDisabled));
  const updateSearchAlert = searchParams.get('updateSearchAlert');
  const showCreateSearchAlert = searchParams.get('showCreateSearchAlert');

  React.useEffect(() => {
    // trigger the search alert dialog after redirecting back from login
    if (!isDisabled && showCreateSearchAlert) {
      setDialogType('main');

      setSearchParams(prevParams => {
        prevParams.delete('showCreateSearchAlert');

        return prevParams;
      });
    }
  }, [
    isDisabled,
    searchParams,
    setDialogType,
    showCreateSearchAlert,
    setSearchParams,
  ]);

  React.useEffect(() => {
    // trigger the search alert dialog when the user wants to edit an alert and
    // the filters have been changed in the mobile filters modal.
    if (!md && hasAlert && !isDisabled && updateSearchAlert) {
      setDialogType('main');
      setSearchParams(prevParams => {
        prevParams.delete('updateSearchAlert');

        return prevParams;
      });
    }
  }, [
    md,
    hasAlert,
    isDisabled,
    updateSearchAlert,
    setDialogType,
    setSearchParams,
  ]);

  const onDialogDismiss = React.useCallback(() => {
    setDialogType(undefined);
    removeUpdateSearchAlertParam();
  }, [removeUpdateSearchAlertParam, setDialogType]);

  const onDialogSubmit = React.useCallback(
    async (
      title: string,
      channel: SearchAlertChannel,
      frequency: SearchAlertFrequency,
      newUserPhone?: PhoneNumberProps,
    ) => {
      if (!channel || !frequency || !title) {
        return;
      }

      try {
        if (newUserPhone) {
          const newUserData = {
            phone: newUserPhone.phone,
            phoneCountryCode: newUserPhone.code,
          };

          await dispatch(updateUser(userId, newUserData));
        }
        const last24hoursOnly = channel === 'whatsapp';

        await dispatch(
          upsertSearchAlert(title, frequency, channel, last24hoursOnly),
        );
      } catch (err) {
        setDialogType(undefined);
        const errors = err?.cause?.response?.errors;
        dispatch(
          showAlert({
            text:
              errors?.[0].key === 'phone'
                ? T('incorrect phone number, validation failed')
                : T('We could not save your changes. Please try again!'),
            kind: AlertType.Danger,
            dismissAfter: 3000,
          }),
        );
        return;
      }

      trackEvent('search alert channel submitted', {
        channel,
      });

      removeUpdateSearchAlertParam();
      setDialogType(!hasAlert ? 'response-create' : 'response-update');
    },
    [
      trackEvent,
      removeUpdateSearchAlertParam,
      setDialogType,
      hasAlert,
      dispatch,
      userId,
      T,
    ],
  );

  const onResponseDialogDismiss = React.useCallback(() => {
    setDialogType(undefined);
  }, [setDialogType]);

  return (
    <NoSSR>
      <RebrandFontProvider>
        <SearchAlertDialog
          modalType={hasAlert ? 'update' : 'create'}
          isOpen={dialogType === 'main' && isAuthenticated}
          onSubmit={onDialogSubmit}
          onClose={onDialogDismiss}
          alert={currentAlert}
          defaultTitle={cityNameLocalized}
        />
        <SearchAlertResponseDialog
          isOpen={
            dialogType === 'response-create' || dialogType === 'response-update'
          }
          hasAlert={dialogType === 'response-update'}
          onClose={onResponseDialogDismiss}
        />
      </RebrandFontProvider>
    </NoSSR>
  );
};
