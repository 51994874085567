import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { SearchResultsHeader } from 'ha/pages/SearchRedesign/components/SearchResultsHeader/SearchResultsHeader';

const useStyles = makeStyles()(theme => ({
  container: {
    background: theme.palette.neutral[100],
    paddingInline: theme.utils.spacing('ref/spacing/5'),
  },
}));

export const MobileMapHeader = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SearchResultsHeader />
    </div>
  );
};
