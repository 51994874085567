import React from 'react';

import { useSelector } from 'react-redux';

import { Button } from '@hbf/dsl/core';
import { Place } from '@hbf/icons/brand-fill';
import { ListAlt } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';

import { useMapViewHandler } from 'ha/pages/Search/hooks/useMapViewHandler';
import { getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

export const SearchMapButtonToggle = ({ origin = '' }: { origin: string }) => {
  const { T } = useIntl();

  const currentView = useSelector(getMapViewState);

  const { changeMapView } = useMapViewHandler();

  return (
    <Button
      variant="outlined"
      size="medium"
      color="neutral"
      startIcon={currentView === MapViewState.noMap ? <Place /> : <ListAlt />}
      onClick={() => {
        const nextView =
          currentView === MapViewState.halfMap
            ? MapViewState.noMap
            : MapViewState.halfMap;
        changeMapView(nextView);
      }}
      data-test-locator={`${origin}.SearchMapButtonToggle`}
    >
      {currentView === MapViewState.noMap
        ? T('search.page.corner_image.switch_to_map')
        : T('search.page.corner_image.switch_to_list')}
    </Button>
  );
};
