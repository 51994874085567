import { handleActions } from 'redux-actions';

import pick from 'lodash-es/pick';

import { Actions } from '../constants';
import { MapViewState } from '../types';

const initialState = {
  location: '',
  uniID: null,

  cityLocalized: '',
  countryLocalized: '',

  latitude: 0,
  longitude: 0,
  initialLatitude: 0,
  initialLongitude: 0,
  radius: 0,
  zoomLevel: null,
  initialZoomLevel: null,
  bounds: undefined,

  isMobileMapOpen: false,

  mapViewState: MapViewState.noMap,
  visitedRooms: {},
};

const map = handleActions(
  {
    [Actions.LOAD_DONE]: (state, { payload }) => ({
      ...state,
      ...pick(payload, ['cityLocalized', 'countryLocalized', 'radius']),
      latitude: state.latitude ? state.latitude : payload.latitude,
      longitude: state.longitude ? state.longitude : payload.longitude,
      zoomLevel: state.zoomLevel ? state.zoomLevel : payload.zoomLevel,
      initialLatitude: payload.latitude,
      initialLongitude: payload.longitude,
      initialZoomLevel: payload.zoomLevel,
    }),

    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { location, uniID, view, lLng, rLng, tLat, bLat } },
    ) => ({
      ...state,
      location,
      uniID,
      ...(view &&
        view in MapViewState && {
          mapViewState: view,
        }),
      ...(view === MapViewState.fullMap && {
        isMobileMapOpen: true,
      }),
      ...(lLng &&
        rLng &&
        tLat &&
        bLat && {
          bounds: {
            leftLng: Number(lLng),
            rightLng: Number(rLng),
            topLat: Number(tLat),
            bottomLat: Number(bLat),
          },
        }),
    }),

    [Actions.UPDATE_MAP]: (
      state,
      { payload: { latitude, longitude, zoom } },
    ) => ({
      ...state,
      latitude,
      longitude,
      zoomLevel: zoom,
    }),

    [Actions.UPDATE_BOUNDS]: (state, { payload: { bounds } }) => ({
      ...state,
      bounds,
    }),

    [Actions.TOGGLE_MOBILE_MAP]: state => ({
      ...state,
      isMobileMapOpen: !state.isMobileMapOpen,
      mapViewState:
        state.mapViewState === MapViewState.fullMap
          ? MapViewState.noMap
          : MapViewState.fullMap,
    }),

    [Actions.UPDATE_MAP_VIEW]: (state, { payload: value }) => ({
      ...state,
      mapViewState: value,
    }),

    [Actions.SET_VISITED_ROOMS]: (state, { payload: { visitedRooms } }) => ({
      ...state,
      visitedRooms,
    }),

    [Actions.RESET_MAP]: state => ({
      ...state,
      latitude: state.initialLatitude || initialState.latitude,
      longitude: state.initialLongitude || initialState.longitude,
      zoomLevel: state.initialZoomLevel || initialState.zoomLevel,
    }),
  },
  initialState,
);
export { map };
