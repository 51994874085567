import { getSearchParams, getBounds } from '../selectors';

import { updateSearchParams, setPreviousParams } from './actions';
import { loadSearchResults } from './loadSearchResults';
import { saveSearch } from './saveSearch';

const load = (reqParams, cityInfo, rates) => (dispatch, getState) => {
  dispatch(updateSearchParams(reqParams));
  const shouldUseMap = Boolean(
    reqParams.lLng && reqParams.rLng && reqParams.tLat && reqParams.bLat,
  );
  const state = getState();
  const searchParams = getSearchParams(state);

  const mapBounds = getBounds(state);

  const searchParamsWithMapBounds = {
    ...searchParams,
    ...(shouldUseMap && {
      ...mapBounds,
    }),
  };

  return dispatch(
    loadSearchResults(
      searchParamsWithMapBounds,
      {
        localizedKind: reqParams.localizedKind,
        priceAverage: reqParams.priceAverage,
      },
      cityInfo,
      rates,
    ),
  )
    .then(() => {
      dispatch(saveSearch());
      dispatch(setPreviousParams(searchParamsWithMapBounds));
    })
    .catch(error => Promise.reject(error));
};

export { load };
