import identity from 'lodash-es/identity';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import mapValues from 'lodash-es/mapValues';
import pick from 'lodash-es/pick';
import pickBy from 'lodash-es/pickBy';
import { createSelector } from 'reselect';

import { isNorthAmericanCountry } from '@ha/core/SupportedCountries';
import { normalizeDate } from '@ha/date';

import { TypeValue } from 'ha/types/SearchFilters';
import { getFilterTypesForAnalytics } from 'ha/utils/filters/getFilterTypesForAnalytics';
import { RequiredAnalyticsFilters } from 'ha/utils/filters/types';

import { DefaultDistributionSteps, STATE_NAME } from '../constants';
import {
  getAdvertiserRatingFilter as getAdvertiserRating,
  getBillsFilter as getBills,
  getCategoriesFilter as getCategories,
  getCollectionFilter as getCollection,
  getFurnitureFilter as getFurniture,
  getGenderFilter as getGender,
  getRecentlyAddedFilter as getRecentlyAdded,
  getRegistrationFilter as getRegistration,
  getRulesFilter as getRules,
  getSuitableForFilter as getSuitableFor,
} from '../reducer';
import { RootState } from '../reducer/types';
import { transformFiltersToSearchParamsSubset } from '../utils/transformFiltersToSearchParamsSubset';

import { getFilterValues } from './filterSelectors';
import { getNumberOfListings, getPageInfo } from './getPageInfo';
import { getIsSearchEmpty, getListings } from './listingsSelectors';
import { getCityCanonical, getCountryCode } from './loadStateSelectors';
import * as mapSelectors from './mapSelectors';
import {
  getCurrencyRates,
  getPriceFilterCurrency,
  getPriceFilterDefaultCurrency,
} from './priceSelectors';
import { getUniversities } from './searchSelectors';

export * from './getRequestCache';
export { getNumberOfListings, getPageInfo };

export {
  getCurrentIndex,
  getCurrentSorting,
  getSortingOptions,
} from './sortingSelectors';

export {
  getCityCanonical,
  getCountryCode,
  getCurrencyRates,
  getIsSearchEmpty,
  getListings,
  getPriceFilterDefaultCurrency,
  getUniversities,
};

const getSearch = (state: RootState) => state[STATE_NAME];

export const getFurnitureFilter = createSelector(getSearch, getFurniture);
export const getAdvertiserRatingFilter = createSelector(
  getSearch,
  getAdvertiserRating,
);
export const getBillsFilter = createSelector(getSearch, getBills);
export const getCategoriesFilter = createSelector(getSearch, getCategories);
export const getCollectionFilter = createSelector(getSearch, getCollection);
export const getRegistrationFilter = createSelector(getSearch, getRegistration);
export const getRulesFilter = createSelector(getSearch, getRules);
export const getSuitableForFilter = createSelector(getSearch, getSuitableFor);
export const getRecentlyAddedFilter = createSelector(
  getSearch,
  getRecentlyAdded,
);
export const getGenderFilter = createSelector(getSearch, getGender);
export const getlangURLListState = createSelector(
  getSearch,
  search => search.langURLList,
);
export const getCitiesNearbyState = createSelector(
  getSearch,
  search => search.citiesNearby,
);
export const getDateFilter = createSelector(
  getSearch,
  search => search.dateFilter,
);
export const getMapLocation = mapSelectors.getLocation;

export const getCityName = mapSelectors.getCityLocalized;
export const getCountryName = mapSelectors.getCountryLocalized;
export const getCitiesNearby = createSelector(
  getCitiesNearbyState,
  cities => cities.citiesNearby,
);

export const getIsAdvertiserSearch = createSelector(getPageInfo, pageInfo =>
  Boolean(pageInfo.userId),
);

export const getAdvertiserName = createSelector(
  getPageInfo,
  pageInfo => pageInfo.advertiserName,
);

export const getAdvertiserId = createSelector(
  getPageInfo,
  pageInfo => pageInfo.userId,
);

const getLoadState = createSelector(getSearch, search => search.loadState);

export const getCountryCanonical = createSelector(
  getLoadState,
  loadState => loadState.countryCanonical,
);
export const getCityLocalized = createSelector(
  getLoadState,
  loadState => loadState.cityLocalized,
);
export const getCountryLocalized = createSelector(
  getLoadState,
  loadState => loadState.countryLocalized,
);
export const getCitySearch = createSelector(
  getLoadState,
  loadState => loadState.citySearch,
);
export const getIsImperialSystem = createSelector(getCountryCode, countryCode =>
  isNorthAmericanCountry(countryCode),
);
export const getErrorState = createSelector(
  getLoadState,
  loadState => loadState.error,
);
export const isNotFound = createSelector(
  getLoadState,
  loadState => loadState.notFound,
);
export const isUnsupportedCountry = createSelector(
  getLoadState,
  loadState => loadState.unsupportedCountry,
);
export const getIsLoading = createSelector(
  getLoadState,
  loadState => loadState.loading,
);

export const getListingsSummary = createSelector(
  getSearch,
  search => search.listingsSummary,
);

export const getAnalyticsForListings = createSelector(
  [getPageInfo, getListings, getListingsSummary],
  (pageInfo, listings, { organic, partner, isExhaustive }) => ({
    numberOfPartnerListings: partner,
    numberOfOrganicListings: organic,
    listingIdResults: listings.map(({ internalID }) => internalID),
    unitTypeIdResults: listings.map(
      ({ unitTypeInternalID }) => unitTypeInternalID,
    ),
    numberOfSearchResults: pageInfo.total,
    isNumberOfSearchResultsExhaustive: pageInfo.isExhaustive,
    areCountersOfOrganicAndPartnerListingsExhaustive: isExhaustive,
  }),
);

export const getLocation = mapSelectors.getSearchQuery;

export const getDateFilterValues = createSelector([getDateFilter], filters =>
  mapValues(pick(filters, ['startDate', 'endDate']), normalizeDate),
);

export const isMobileMapOpen = mapSelectors.getIsMobileMapOpen;

export const {
  getMapViewState,
  getMapVisitedRooms,
  isMarkerVisited,
  isMarkerFavorite,
  getBounds,
} = mapSelectors;

export const isSearchByPartner = createSelector(
  mapSelectors.getUniId,
  uniId => !!uniId,
);

export const getCitiesNearbyFilter = createSelector(
  [getCitiesNearbyState, getCitySearch],
  ({ limit, minPopulation, minRooms }, citySearch) => ({
    citySearch,
    cityLimit: limit,
    cityMinPopulation: minPopulation,
    cityMinRooms: minRooms,
  }),
);

export const getSearchParams = createSelector(
  [getFilterValues, getPageInfo, getCitiesNearbyFilter, getCitySearch],
  (filterValues, pageInfo, citiesNearbyFilter, citySearch) => {
    const reqParams = {
      ...transformFiltersToSearchParamsSubset(filterValues),
      ...citiesNearbyFilter,

      citySearch,

      offset: pageInfo.offset,
      limit: pageInfo.limit,
      advertiserId: pageInfo.userId,

      // Constants
      priceDistributionSteps: DefaultDistributionSteps,
    };

    return pickBy(reqParams, val => !isNil(val));
  },
);

export const getMapParams = createSelector(
  [
    mapSelectors.getLatitude,
    mapSelectors.getLongitude,
    mapSelectors.getZoomLevel,
  ],
  (latitude, longitude, zoomLevel) => ({
    latitude,
    longitude,
    zoomLevel,
  }),
);

export const getInitialMapParams = createSelector(
  [
    mapSelectors.getInitialLatitude,
    mapSelectors.getInitialLongitude,
    mapSelectors.getInitialZoomLevel,
  ],
  (initialLatitude, initialLongitude, initialZoomLevel) => ({
    initialLatitude,
    initialLongitude,
    initialZoomLevel,
  }),
);

export const getAnalyticsForFilters = createSelector(
  [
    getAnalyticsForListings,
    getSearchParams,
    getMapParams,
    getInitialMapParams,
    getCurrencyRates,
    getPriceFilterCurrency,
    getCityCanonical,
    getCountryCanonical,
    getBounds,
  ],
  (
    listingsAnalytics,
    searchParams,
    mapParams,
    initialMapParams,
    currencyRates,
    currency,
    cityCanonical,
    countryCanonical,
    mapBoundaries,
    // eslint-disable-next-line max-params
  ) => {
    const currencyRate = currencyRates[currency];

    const hasCoords = Boolean(
      mapBoundaries?.leftLng &&
        mapBoundaries.rightLng &&
        mapBoundaries.topLat &&
        mapBoundaries.bottomLat,
    );

    const normalizePrice = (amount: number) =>
      Math.round(amount / 100 / currencyRate);

    const normalizeDateToISO = (date: string) => new Date(date).toISOString();

    const filters = pickBy(
      pick(searchParams, Object.keys(RequiredAnalyticsFilters)),
      identity,
    );

    if (filters.priceMax) {
      filters.priceMax = normalizePrice(Number(filters.priceMax));
    }

    if (filters.priceMin) {
      filters.priceMin = normalizePrice(Number(filters.priceMin));
    }

    if (filters.startDate) {
      const normalizedDate = normalizeDateToISO(String(filters.startDate));

      filters.startDate = normalizedDate;
      filters.startDate2 = normalizedDate;
    }

    if (filters.endDate) {
      const normalizedDate = normalizeDateToISO(String(filters.endDate));

      filters.endDate = normalizedDate;
      filters.endDate2 = normalizedDate;
    }

    let { filterTypesWithValues } = getFilterTypesForAnalytics(filters);
    delete filterTypesWithValues.searchArea;

    const { zoomLevel } = mapParams;
    const { initialZoomLevel } = initialMapParams;

    if (hasCoords && zoomLevel && zoomLevel !== initialZoomLevel) {
      filterTypesWithValues = { ...filterTypesWithValues, zoomLevel };
    }

    if (!isEmpty(filters) || searchParams.topLat) {
      return {
        listingcity: cityCanonical,
        listingcountry: countryCanonical,
        currency: searchParams.currency,
        ...listingsAnalytics,
        ...RequiredAnalyticsFilters,
        ...filters,
        filterTypes: Object.keys(filterTypesWithValues),
        filterTypesWithValues,
      };
    }

    return {};
  },
);

export const getUniversity = createSelector(
  [mapSelectors.getUniId, getUniversities],
  (uniId, universities) => universities.find(({ id }) => id === uniId) || null,
);

export const getPageName = createSelector(
  [getUniversity, getCollectionFilter],
  (university, category) => {
    if (university) {
      return university.name;
    }

    const propertyTypes = {
      [TypeValue.APARTMENT]: 'Apartments',
      [TypeValue.PRIVATE_ROOM]: 'Private Rooms',
      [TypeValue.SHARED_ROOM]: 'Shared Rooms',
      [TypeValue.STUDIO]: 'Studios',
    };

    return propertyTypes[category] ?? 'All accommodation';
  },
);

export { getPagerProps } from './getPagerProps';

export const getPriceAverage = createSelector(
  getLoadState,
  loadState => loadState.priceAverage,
);
