import { useSearchParams } from 'react-router-dom';

function getShouldUseMapBounds(searchParams: URLSearchParams) {
  return Boolean(
    searchParams.get('lLng') &&
      searchParams.get('rLng') &&
      searchParams.get('tLat') &&
      searchParams.get('bLat'),
  );
}

export const useShouldUseMapBounds = () => {
  const [searchParams] = useSearchParams();
  const shouldUseMap = getShouldUseMapBounds(searchParams);

  return { shouldUseMap };
};
