import { createSelector } from 'reselect';

import { Experiments } from 'ha/constants/experiments';

import { getFeatureFlags, isEnabled } from 'ha/modules/FeatureFlags';
import { ABExperiment } from 'ha/modules/FeatureFlags/types';

import { getSelectedVariants } from 'ha/modules/Experiments/selectors';

export const getSplitMapExperimentDesktop = createSelector(
  [getSelectedVariants, getFeatureFlags],
  (experiments, featureFlags) => {
    const variant = experiments[Experiments.TX3103.name] as ABExperiment;

    const isSplitMapDesktopExperimentEnabled = isEnabled(
      featureFlags.enableMapExperimentDesktop,
    );

    return {
      splitMapDesktopExperimentName: Experiments.TX3103.name,
      splitMapDesktopExperimentVariant: variant,
      isSplitMapDesktopExperimentActive:
        isSplitMapDesktopExperimentEnabled && ['A', 'B'].includes(variant),
    };
  },
);

export const getSplitMapExperimentMobile = createSelector(
  [getSelectedVariants, getFeatureFlags],
  (experiments, featureFlags) => {
    const variant = experiments[Experiments.TX3104.name] as ABExperiment;

    const isSplitMapMobileExperimentEnabled = isEnabled(
      featureFlags.enableMapExperimentMobile,
    );

    return {
      splitMapMobileExperimentName: Experiments.TX3104.name,
      splitMapMobileExperimentVariant: variant,
      isSplitMapMobileExperimentActive:
        isSplitMapMobileExperimentEnabled && ['A', 'B'].includes(variant),
    };
  },
);
