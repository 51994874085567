import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import omit from 'lodash-es/omit';

import { ListingRentalPeriodType } from '@ha/api/v2/types/Listing';
import { normalizeDate } from '@ha/date';
import { track } from '@hbf/analytics';

import { isHaMobileApp } from 'ha/helpers/isHaMobileApp';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { FacilitiesValues } from 'ha/models/ListingFacilities/constants';
import { useFeatureFlags } from 'ha/modules/FeatureFlags';
import { ListingCardLocations } from 'ha/types/ListingCard';
import { searchParamsToObject } from 'ha/utils/routes/searchParamsToObject';

import { ListingCard } from 'ha/components/Redesign/ListingCard';
import { MarketTypeVariant } from 'ha/modules/PBSA/types';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider';

import { setVisitedRoom } from '../actions';
import { getMapViewState } from '../selectors';
import { getAvailability } from '../selectors/filterSelectors';

export const EVENT_NAME = 'Search listing clicked';

interface Props {
  listingId: number;
  pageInfo: { offset: number; limit: number };
  cityCanonical: string;
  countryCode: string;
  filtersAnalytics: {
    listingIdResults: number[];
    numberOfOrganicListings: number;
    numberOfPartnerListings: number;
    numberOfSearchResults: number;
  };
  listingsAnalytics: {
    listingIdResults: number[];
  };
  trackLocation?: ListingCardLocations;
  isAdvertiserSearch: boolean;
  room: NormalizedAlgoliaListing;
  onMouseEnter?: (id: number) => void;
  onMouseLeave?: (id: number) => void;
  isLazy?: boolean;
  withGallery?: boolean;
  withFavorites?: boolean;
  isMapMarker?: boolean;
  isMobile: boolean;
  isImperialSystem: boolean;
  rentalPeriodType: ListingRentalPeriodType;
}

const ListingPreviewCardTracked: React.FC<Props> = ({
  cityCanonical,
  countryCode,
  filtersAnalytics,
  pageInfo: { offset, limit },
  listingId,
  listingsAnalytics: { listingIdResults },
  isAdvertiserSearch,
  trackLocation,
  isMobile,
  isImperialSystem,
  rentalPeriodType,
  ...listingProps
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { md } = useMediaQuery();

  const mapViewCurrentState: string = useSelector(getMapViewState);

  const marketType = React.useMemo(() => {
    if (listingProps.room.coLiving === FacilitiesValues.Yes) {
      return MarketTypeVariant.CO_LIVING;
    }
    if (listingProps.room.studentHousing === FacilitiesValues.Yes) {
      return MarketTypeVariant.STUDENT_HOUSING;
    }
    return null;
  }, [listingProps]);

  const listingCardPosition = React.useMemo(
    () => offset + listingIdResults.indexOf(listingId) + 1,
    [offset, listingId, listingIdResults],
  );

  const handleCardClick = React.useCallback(() => {
    const params = {
      type: 'link',
      listingcity: cityCanonical,
      countryCode,
      ...omit(filtersAnalytics, [
        'listingIdResults',
        'numberOfOrganicListings',
        'numberOfPartnerListings',
        'numberOfSearchResults',
        'countryCanonical',
      ]),
      position: listingCardPosition,
      page: Math.floor(offset / limit) + 1,
      pageType: isAdvertiserSearch ? 'advertiser-search' : 'search',
      cardLocation: trackLocation,
      isAdvancedPricing: listingProps.room.priceType === 'monthly',
      ...(marketType ? { marketType } : {}),
      ismul: listingProps.room.isMultiUnit,
      rentalperiod:
        rentalPeriodType === ListingRentalPeriodType.STRICT
          ? 'strict'
          : 'flexible',
      ...(md && { mapType: mapViewCurrentState }),
      unittypeid: listingProps.room.unitTypeId,
      with_orpheus_score: listingProps.room.rankWithOrpheus,
      with_orpheus_experiment_score:
        listingProps.room.rankWithOrpheusExperiment,
    };

    track(EVENT_NAME, params);

    dispatch(setVisitedRoom(listingId));
  }, [
    cityCanonical,
    countryCode,
    filtersAnalytics,
    listingCardPosition,
    offset,
    listingId,
    limit,
    isAdvertiserSearch,
    trackLocation,
    marketType,
    rentalPeriodType,
    md,
    mapViewCurrentState,
    dispatch,
    listingProps.room,
  ]);

  const { flexibleSearchDates } = useFeatureFlags();
  const availabilityFilterValue = useSelector(getAvailability);

  const avoidDateQuery = useMemo(
    () =>
      flexibleSearchDates === 'on' &&
      availabilityFilterValue?.moveIn.type === 'month',
    [flexibleSearchDates, availabilityFilterValue],
  );

  const queryParams = React.useMemo(() => {
    return {
      ...searchParamsToObject(searchParams),
      ...(avoidDateQuery
        ? {}
        : {
            startDate:
              normalizeDate(searchParams.get('startDate')) || undefined,
            endDate: normalizeDate(searchParams.get('endDate')) || undefined,
          }),
    };
  }, [searchParams, avoidDateQuery]);

  const props = {
    ...listingProps,
    queryParams,
    onCardClick: handleCardClick,
    cardLocation: trackLocation,
    isImperialSystem,
  };

  return (
    <RebrandThemeProvider>
      <ListingCard
        {...props}
        linkTarget={isMobile || isHaMobileApp() ? undefined : '_blank'}
        cardPosition={listingCardPosition}
      />
    </RebrandThemeProvider>
  );
};

export { ListingPreviewCardTracked };
